import Col from "react-bootstrap/Col";
import InfoSideBar from "./InfoSideBar";
import React from "react";
import RightBar from "./RightBar";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";


const ContentPage = (props: { component: any }) => {
    const Component = props.component;

    return (
        <Row>
            <Col xs="auto" className="m-0 p-0">
                <InfoSideBar/>
            </Col>
            <Col className="mx-lg-5 p-4 content-page mt-3">
                <Container>
                    <Row>
                        <Col>
                            <Component/>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <RightBar/>
        </Row>
    )
};

export default ContentPage;