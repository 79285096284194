import {NavLink} from "react-router-dom";
import {Sidebar as ProSideBar, Menu, MenuItem} from 'react-pro-sidebar';
import {faCookie, faList, faGlasses, faLock} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ResponsiveIcon from "./ResponsiveIcon";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function InfoSideBar() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(992));
    const className = isMobile ? "" : "my-3 menu-item-border"
    return (
        <div className="d-flex d-none d-lg-block mx-0 p-0 sidebar" id="sidebar">
            <ProSideBar>
                <Menu>
                    <MenuItem component={<NavLink to="/terms-of-use" />} icon={<ResponsiveIcon icon={faList}/>} className={className}>Terms</MenuItem>
                    <MenuItem component={<NavLink to="/disclaimer" />} icon={<ResponsiveIcon icon={faLock}/>} className={className}>Disclaimer</MenuItem>
                    <MenuItem component={<NavLink to="/privacy-policy" />} icon={<ResponsiveIcon icon={faGlasses}/>} className={className}>Privacy</MenuItem>
                    <MenuItem component={<NavLink to="/cookie-policy" />} icon={<ResponsiveIcon icon={faCookie}/>} className={className}>Cookies</MenuItem>
                </Menu>
            </ProSideBar>
        </div>
    );
}

export default InfoSideBar;