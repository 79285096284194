import {auth} from "../config/firebase";
import {onAuthStateChanged} from "firebase/auth";
import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function UserButton(props: { isDarkMode: boolean }) {
    const [user, setUser] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        // Set up an authentication observer
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(!!user);
        });

        // Clean up the observer when the component unmounts
        return () => unsubscribe();
    }, []);

    return user ?
        (<Link to="/user/settings"><FontAwesomeIcon icon={faUser} style={{color: props.isDarkMode ? "#ffffff" : "#000000", filter: 'grayscale(0) invert(0)'}}/></Link>) : (
            <Link to="/login"><FontAwesomeIcon icon={faUser} style={{color: props.isDarkMode ? "#ffffff" : "#000000", filter: 'grayscale(0) invert(0)'}}/></Link>
        )
}

export default UserButton;