import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import UserButton from "./UserButton";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TickerSearchBar from "./TickerSearchBar";
import {
    enable as enableDarkMode,
    disable as disableDarkMode,
} from 'darkreader';
import { DarkModeSwitch } from 'react-toggle-dark-mode';


function Menu(props: { displaySidebar: boolean }) {
    const [isDarkMode, setDarkMode] = React.useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === null ? false : savedMode === 'true';
    });

    React.useEffect(() => {
        if (isDarkMode) {
            // @ts-ignore
            enableDarkMode({ brightness: 100,  contrast: 100, sepia: 0, darkSchemeBackgroundColor: '#0D1217' },{ ignoreInlineStyle: ['circle'] })
        } else {
            disableDarkMode();
        }
        window.dispatchEvent(new Event("darkModeUpdated"));
    }, [isDarkMode]);

    const toggleDarkMode = (checked: boolean) => {
        setDarkMode(checked);
        localStorage.setItem('darkMode', checked.toString());
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const childElement = document.getElementById('sidebar');
            if (event.target instanceof Element && event.target.className === 'navbar-toggler-icon') {
                return
            }
            if (childElement && !childElement.contains(event.target as Node)) {
                document.getElementById('sidebar')?.classList.add('d-none');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <Navbar expand="lg" className="navbar-container">
            <Container className="p-0 px-2">
                {props.displaySidebar ? (<Navbar.Toggle id="toggle" onClick={() => {
                    document.getElementById('sidebar')?.classList.toggle('d-none');
                }}></Navbar.Toggle>) : null}
                <Navbar.Brand href="/" className="brand"><img src='/logo.webp' className="logo"
                                                              alt="logo" style={{filter: isDarkMode ? 'brightness(125%)' : ''}}/></Navbar.Brand>
                <Nav className="me-auto d-contents">
                    <Row className="me-auto d-contents m-0">
                        <Col className="p-2">
                            <TickerSearchBar/>
                        </Col>
                        <Col xs="auto" className="mx-0 p-2">
                            <DarkModeSwitch
                                style={{ marginRight: '1rem' }}
                                checked={isDarkMode}
                                onChange={toggleDarkMode}
                                size={20}
                            />
                            <UserButton isDarkMode={isDarkMode}/>
                        </Col>
                    </Row>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default Menu;