import React from "react";
import Box from "../components/Box";
import ActionForm from "../components/ActionForm";

function Action() {
    return (
        <Box component={<ActionForm/>}/>
    );
}

export default Action;