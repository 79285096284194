import React, {useState} from "react";
import {auth} from "../config/firebase";
import {
    updatePassword
} from "firebase/auth";
import Message from "./Message";

function UpdatePasswordForm() {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [message, setMessage] = useState("")
    const [messageClass, setMessageClass] = useState("")

    const updatePass = async (e: any) => {
        e.preventDefault();
        if (!auth.currentUser) {
            return
        }
        if (password === '') {
            setMessage("Password is empty")
            setMessageClass("text-danger")
            return
        }
        if (password !== confirmPassword) {
            setMessage("Passwords do not match")
            setMessageClass("text-danger")
            return
        }
        try {
            await updatePassword(auth.currentUser, password);
            setMessage("Password updated")
            setMessageClass("text-success")
        } catch (e: any) {
            setMessage(e.message.replace("Firebase: ", ""))
            setMessageClass("text-danger")
        }
    }

    return (
            <form onSubmit={updatePass} className="rounded-1 p-3 pt-4 auth-form mb-3">
                <h5 className="mx-2 mb-4 fw-bold">Password</h5>
                <div className="form-group p-2">
                    <label className="mb-1">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className="form-group p-2">
                    <label className="mb-1">Confirm</label>
                    <input
                        type="password"
                        className="form-control"
                        onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                </div>
                <div className="form-group p-2">
                    <button type="submit" className="btn btn-primary mt-2 log-in-button numeric-dark-blue">
                        Update
                    </button>
                </div>
                <div className="form-group m-2">
                    <Message message={message} class={messageClass}/>
                </div>
            </form>
    );
}

export default UpdatePasswordForm;