import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UserSideBar from "../components/UserSideBar";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../config/firebase";
import UpdatePasswordForm from "../components/UpdatePasswordForm";
import VerifyEmailForm from "../components/VerifyEmailForm";
import RightBar from "../components/RightBar";
import Container from "react-bootstrap/Container";

function UserSettings() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Set up an authentication observer
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                window.location.href = "/"
            }
            setLoading(false)
        });

        // Clean up the observer when the component unmounts
        return () => unsubscribe();
    }, []);

    return (
            <Row style={{ visibility: loading ? "hidden" : "visible" }}>
                <Col xs="auto" className="m-0 p-0">
                    <UserSideBar/>
                </Col>
                <Col className="m-0 p-0 mx-lg-5 mt-3">
                    <Container>
                        <Row>
                            <Col>
                                <VerifyEmailForm/>
                                <UpdatePasswordForm/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <RightBar/>
            </Row>
    )
}

export default UserSettings;