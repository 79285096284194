import { useEffect, useRef } from 'react';

const useWebSocket = (onmessage: (event: MessageEvent) => void) => {
    const wsRef = useRef<WebSocket | null>(null);
    const retryInterval = useRef<number>(5000); // Retry interval in milliseconds

    useEffect(() => {
        const connectWebSocket = () => {
            const url = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}${window.location.protocol === 'https:' ? '' : ':3030'}/ws/`;
            const ws = new WebSocket(url);
            wsRef.current = ws;

            ws.onopen = () => {
                retryInterval.current = 5000; // Reset retry interval on successful connection
            };

            ws.onmessage = onmessage;

            ws.onclose = () => {
                reconnectWebSocket();
            };

            ws.onerror = (error) => {
                ws.close();
            };
        };

        const reconnectWebSocket = () => {
            setTimeout(() => {
                if (wsRef.current?.readyState === WebSocket.CLOSED) {
                    connectWebSocket();
                    // Incremental backoff (optional)
                    retryInterval.current = Math.min(retryInterval.current * 2, 30000); // Max retry interval is 30 seconds
                }
            }, retryInterval.current);
        };

        connectWebSocket();

        return () => {
            wsRef.current?.close();
        };
    }, [onmessage]);

    return wsRef.current;
};

export default useWebSocket;