function limitSignificantDigits(num: number, maxDigits: number) {
    let numStr = num.toString();
    if (numStr.includes('e')) {
        return parseFloat(num.toPrecision(maxDigits));
    }
    const dotIndex = numStr.indexOf('.');
    const limit = dotIndex !== -1 ? maxDigits + 1 : maxDigits;
    let roundedStr = parseFloat(numStr.slice(0, limit + (dotIndex !== -1 ? 1 : 0))).toPrecision(maxDigits);
    return parseFloat(roundedStr);
}

export default limitSignificantDigits