import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { Sidebar as ProSideBar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { faCogs, faComment, faChartBar } from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Toggle from "react-toggle";
import ResponsiveIcon from "./ResponsiveIcon";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function SideBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [postType, setPostType] = React.useState('all');
    const [range, setRange] = React.useState('1m');
    const [searchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(992));
    const [columns, setColumns] = useState<Map<string, boolean>>(new Map([
        ['bookmark', true],
        ['volume', !isMobile],
        ['marketCap', true],
    ]));

    useEffect(() => {
        const storedColumns = localStorage.getItem('columns');
        if (storedColumns) {
            setColumns(new Map(JSON.parse(storedColumns)));
        }
    }, []);

    const setColumn = (key: string) => {
        setColumns((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(key, !prevMap.get(key)); // Toggle boolean value
            localStorage.setItem('columns', JSON.stringify(Array.from(newMap.entries())));
            return newMap;
        });
        window.dispatchEvent(new Event("updatedColumns"));
    };

    const handlePostTypeChange = (m: string) => {
        setPostType(m)
        setQueryParameter([{key: 'type', value: m}]);
    };

    const handleRangeChange = (m: string) => {
        setRange(m)
        setQueryParameter([{key: 'range', value: m}]);
    };

    const loadParams = () => {
        if (searchParams) {
            const type = searchParams.get("type")
            if (type) {
                setPostType(type)
            } else {
                setPostType('all')
            }
            const range = searchParams.get("range")
            if (range) {
                setRange(range)
            } else {
                setRange('1m')
            }
        }
    }

    const setQueryParameter = (params: {key: string, value: string}[]) => {
        const searchParams = new URLSearchParams(location.search);
        for (let i = 0; i < params.length; i++) {
            searchParams.set(params[i].key, params[i].value);
        }
        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    };

    const ChartSubMenu = () => {
        const display = location.pathname.startsWith('/symbol')
        return display ? (
            <SubMenu label="Chart" defaultOpen={true} icon={<ResponsiveIcon icon={faChartBar}/>}>
                <MenuItem component={<b onClick={() => handleRangeChange('1d')}/>} className="submenu-item">1 Day<Toggle onChange={() => handleRangeChange('1d')} checked={range === '1d'} icons={false} className="float-end custom-toggle"/></MenuItem>
                <MenuItem component={<b onClick={() => handleRangeChange('1m')}/>} className="submenu-item">1 Month<Toggle onChange={() => handleRangeChange('1m')} checked={range === '1m'} icons={false} className="float-end custom-toggle"/></MenuItem>
                <MenuItem component={<b onClick={() => handleRangeChange('1y')}/>} className="submenu-item">1 Year<Toggle onChange={() => handleRangeChange('1y')} checked={range === '1y'} icons={false} className="float-end custom-toggle"/></MenuItem>
            </SubMenu>
        ) : <></>
    }

    const NewsSubMenu = () => {
        const display = location.pathname.startsWith('/symbol')
        return display ? (
            <SubMenu label="Posts" defaultOpen={true} icon={<ResponsiveIcon icon={faComment}/>}>
                <MenuItem component={<b onClick={() => handlePostTypeChange('all')}/>} className="submenu-item">All<Toggle onChange={() => handlePostTypeChange('all')} checked={postType === 'all'} icons={false} className="float-end custom-toggle"/></MenuItem>
                <MenuItem component={<b onClick={() => handlePostTypeChange('news')}/>} className="submenu-item">News<Toggle onChange={() => handlePostTypeChange('news')} checked={postType === 'news'} icons={false} className="float-end custom-toggle"/></MenuItem>
                <MenuItem component={<b onClick={() => handlePostTypeChange('social')}/>} className="submenu-item">Social<Toggle onChange={() => handlePostTypeChange('social')} checked={postType === 'social'} icons={false} className="float-end custom-toggle"/></MenuItem>
            </SubMenu>
        ) : <></>
    }

    const TickersSubMenu = () => {
        const display = location.pathname === '/'
        return display ? (
            <SubMenu label="Customize" defaultOpen={true} icon={<ResponsiveIcon icon={faCogs}/>}>
                <MenuItem component={<b onClick={() => setColumn('volume')}/>} className="submenu-item">Volume<Toggle onChange={() => setColumn('volume')} checked={columns.get('volume')} icons={false} className="float-end custom-toggle"/></MenuItem>
                <MenuItem component={<b onClick={() => setColumn('marketCap')}/>} className="submenu-item">Cap<Toggle onChange={() => setColumn('marketCap')} checked={columns.get('marketCap')} icons={false} className="float-end custom-toggle"/></MenuItem>
                <MenuItem component={<b onClick={() => setColumn('bookmark')}/>} className="submenu-item">Bookmark<Toggle onChange={() => setColumn('bookmark')} checked={columns.get('bookmark')} icons={false} className="float-end custom-toggle"/></MenuItem>
            </SubMenu>
        ) : <></>
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadParams, [searchParams])

    return (
        <div className="d-flex d-none d-lg-block mx-0 p-0 sidebar" id="sidebar">
            <ProSideBar>
                <Menu>
                    <ChartSubMenu />
                    <NewsSubMenu />
                    <TickersSubMenu />
                </Menu>
            </ProSideBar>
        </div>
    )
}

export default SideBar;