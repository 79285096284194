import React, {useEffect, useState} from "react";
import {auth} from "../config/firebase";
import {
    onAuthStateChanged,
    sendEmailVerification
} from "firebase/auth";
import Message from "./Message";

function VerifyEmailForm() {
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [messageClass, setMessageClass] = useState("")

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setEmail(user?.email ? user?.email : '');
        });
        return () => unsubscribe();
    }, []);

    const submitVerifyEmail = async (e: any) => {
        e.preventDefault();
        if (!auth.currentUser) {
            return
        }
        if (email === '') {
            setMessage("Email is empty")
            setMessageClass("text-danger")
            return
        }
        try {
            await sendEmailVerification(auth.currentUser)
            setMessage("Confirmation email sent")
            setMessageClass("text-success")
        } catch (e: any) {
            setMessage(e.message.replace("Firebase: ", ""))
            setMessageClass("text-danger")
        }
    }

    return (
            <form onSubmit={submitVerifyEmail} className="rounded-1 p-3 pt-4 auth-form mb-3">
                <h5 className="mx-2 mb-4 fw-bold">Email</h5>
                <label
                    className={auth.currentUser?.emailVerified ? "mx-2 mb-3 small text-secondary text-success" : "mx-2 mb-3 small text-secondary text-danger"}>{auth.currentUser?.emailVerified ? "Your email is verified" : "Please verify your email"}</label>
                <div className="form-group p-2">
                    <label className="mb-2">Email</label>
                    <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)} disabled={true}
                    />
                </div>
                <div className="form-group p-2">
                    <button type="submit"
                            className={auth.currentUser?.emailVerified ? "btn btn-primary mt-2 log-in-button d-none" : "btn btn-primary mt-2 log-in-button"}>
                        Send
                    </button>
                </div>
                <div className="form-group m-2">
                    <Message message={message} class={messageClass}/>
                </div>
            </form>
    );
}

export default VerifyEmailForm;