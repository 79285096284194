import Post from './Post'
import {useEffect, useState} from "react";
import fetchData from "../utils/Fetch";
import Paginate from "./Paginate";
import Loader from "./Loader";
import {useParams, useSearchParams} from "react-router-dom";

const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('')
    const {ticker} = useParams();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let type = 'all'
        if (searchParams) {
            const searchType = searchParams.get("type")
            if (searchType) {
                type = searchType
            }
        }
        fetchData(`/api/posts?page=${page}&pageSize=${pageSize}&keyword=${ticker ? ticker : ''}&type=${type}`, {
            setError,
            setIsLoading,
            setMessage
        }).then((response) => {
            if (response) {
                setPosts(response.posts)
                setTotal(response.total)
            }
        })
    }, [page, pageSize, ticker, searchParams])

    const setPreviousPage = () => {
        if (page !== 1) {
            setPage(page - 1);
        }
    };

    const setPageSizeCallback = (pageSize: number) => {
        setPageSize(pageSize)
        setPage(1)
    };

    const setNextPage = () => {
        if (page !== Math.ceil(total / pageSize)) {
            setPage(page + 1);
        }
    };

    const paginate = (pageNumber: number) => {
        setPage(pageNumber);
    };

    const postsList = () => {
        return (<div className="w-100">
            {posts.map((post: any) => {
                return (
                    <Post post={post} key={post.id}/>
                );
            })}
            <Paginate setPreviousPage={setPreviousPage} setNextPage={setNextPage}
                      paginate={paginate} setPageSize={setPageSizeCallback} pageSize={pageSize} page={page}
                      total={total}
                      pageSizeOptions={[20, 50, 100]}/>
        </div>)
    }

    return (
        <Loader isLoading={isLoading} component={postsList} error={error} message={message}/>
    )
};

export default Posts;