import React from "react";
import Indicator from "./Indicator";
import Container from "react-bootstrap/Container";

function RightBar() {
    return (
        <div className="col-2 d-none d-lg-block m-0 p-0">
            <Container className="mt-3"><Indicator symbol="BTC" name="sentiment" title="Fear & Greed" /></Container>
            <Container className="mt-3">
                <a href="https://coinrule.sjv.io/c/5864581/1634655/18409" target="_blank" id="1634655" rel="noreferrer">
                    <img src="//a.impactradius-go.com/display-ad/18409-1634655" className="border-0 w-100 rounded-1" alt="shopify" />
                </a>
                <img height="0" width="0" src="https://imp.pxf.io/i/5864581/1634655/18409" style={{position:"absolute", visibility:"hidden"}} className="border-0" alt="shopify" />
            </Container>
        </div>
    )
}

export default RightBar;