import React from "react";
import Box from "../components/Box";
import LoginForm from "../components/LoginForm";

function Login() {
    return (
        <Box component={<LoginForm/>}/>
    );
}

export default Login;