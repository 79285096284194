import React from "react";
import Box from "../components/Box";
import SignUpForm from '../components/SignUpForm'

function SignUp() {
    return (
        <Box component={<SignUpForm/>}/>
    );
}

export default SignUp;