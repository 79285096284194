import React, {useState} from "react";
import {auth} from "../config/firebase";
import {
    sendPasswordResetEmail
} from "firebase/auth";
import Message from "./Message";
import {Link} from "react-router-dom";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ResetPasswordForm() {
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const resetPassword = async (e: any) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage("Password reset email sent")
            setMessageClass("text-success")
        } catch (e: any) {
            setMessage(e.message.replace("Firebase: ", ""))
            setMessageClass("text-danger")
        }
    }

    return (
        <div>
            <Link to="/login">
                <button type="submit" className="btn btn-primary w-100 box-up-button">
                    <FontAwesomeIcon icon={faUser} className="fa-sm mx-2"/>Log In
                </button>
            </Link>
            <form onSubmit={resetPassword} className="rounded-3 p-3 mt-3 auth-form">
                <div className="form-group p-2">
                    <label className="font-weight-bold">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                <div className="form-group text-center">
                    <img src="/reCAPTCHA.png" className="recaptcha-logo" alt="recaptcha"/>
                </div>
                <div className="form-group p-2">
                    <button type="submit" className="btn btn-primary log-in-button w-100 mt-2">
                        Reset
                    </button>
                </div>
                <div className="form-group m-2">
                    <Message message={message} class={messageClass}/>
                </div>
            </form>
        </div>
    );
}

export default ResetPasswordForm;