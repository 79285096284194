// api.js
import {auth} from "../config/firebase";
import {appCheck} from "../config/firebase";
import { getToken } from 'firebase/app-check';

const API_PORT = "";

function constructApiUrl(endpoint: string) {
    const url = new URL(endpoint, window.location.origin);
    url.port = API_PORT;
    return url.href;
}

function checkAuthState() {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(
            user => {
                unsubscribe(); // Unsubscribe after getting the user state
                resolve(user); // Resolve with the user (could be null if no user is logged in)
            },
            error => {
                unsubscribe(); // Unsubscribe on error
                reject(error); // Reject the promise on error
            }
        );
    });
}

async function fetchData(endpoint: string, callBacks?: any) {
    if (!callBacks) {
        callBacks = {}
        callBacks.setError = (x?: any) => {
        }
        callBacks.setIsLoading = (x?: any) => {
        }
        callBacks.setMessage = (x?: any) => {
        }
    }
    callBacks.setError('')
    callBacks.setIsLoading(true)
    callBacks.setMessage(undefined)
    if (!auth.currentUser) {
        await checkAuthState()
    }
    const token = await auth.currentUser?.getIdToken()
    const appCheckToken = await getToken(appCheck, false);
    let headers: Record<string, string> = {};
    if (token) {
        headers.Auth = token;
    }
    if (appCheckToken && appCheckToken.token) {
        headers.Token = appCheckToken.token;
    }
    const apiUrl = constructApiUrl(endpoint);
    try {
        const response = await fetch(apiUrl, { headers });
        if (!response.ok) {
            const error = (await response.json()).error
            callBacks.setIsLoading(false)
            callBacks.setError(error)
            return
        }
        const json = await response.json()
        if (json.message) {
            callBacks.setMessage(json.message)
            return
        }
        callBacks.setIsLoading(false)
        return json
    } catch (error: any) {
        callBacks.setIsLoading(false)
        callBacks.setError('Invalid request')
    }
}

export default fetchData