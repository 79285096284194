import {Outlet} from "react-router-dom";
import Menu from "./Menu";
import TickerTape from "./TickerTape";
import React from "react";
import {analytics} from "../config/firebase";
import {useEffect} from "react";
import { logEvent } from "firebase/analytics";

const Layout = (props: { displaySidebar: boolean }) => {
    useEffect(() => {
        logEvent(analytics, "page_view", { page_path: window.location.pathname });
    }, []);

    return (
        <div className="container w-100 header-container">
            <div className="row header">
                <Menu displaySidebar={props.displaySidebar}/>
            </div>
            <div className="content">
                <TickerTape/>
                <Outlet/>
            </div>
            <div className="footer" id="footer">
                <div className="row my-3">
                    <div className="col-12">
                        <ul className="list-unstyled">
                            <li className="list-inline-item"><a href="/">Home</a></li>
                            <li className="list-inline-item">•</li>
                            <li className="list-inline-item"><a href="/terms-of-use">Terms</a></li>
                            <li className="list-inline-item">•</li>
                            <li className="list-inline-item"><a href="/privacy-policy">Privacy</a></li>
                            <li className="list-inline-item">•</li>
                            <li className="list-inline-item"><a href="/cookie-policy">Cookies</a></li>
                            <li className="list-inline-item">•</li>
                            <li className="list-inline-item"><a href="/disclaimer">Disclaimer</a></li>
                            <li className="list-inline-item">•</li>
                            <li className="list-inline-item"><a href="mailto:contact@cointool.com">Contact</a></li>
                            <li className="list-inline-item">•</li>
                            <li className="list-inline-item"><a href="mailto:contact@cointool.com">Advertise</a></li>
                        </ul>
                        <div className="m-0">
                            Copyright © 2024 cointool.com. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Layout;