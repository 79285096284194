import React, {useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ResponsiveIcon(props: {icon: any}) {
    const [isDarkMode, setIsDarkMode] = React.useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === null ? false : savedMode === 'true';
    });

    useEffect(() => {
        const updateMode = () => {
            setTimeout(() => {
                const savedMode = localStorage.getItem('darkMode');
                if (savedMode) {
                    setIsDarkMode(savedMode === 'true')
                }
            }, 0)
        }
        window.addEventListener('darkModeUpdated', updateMode);
        return () => {
            window.removeEventListener('darkModeUpdated', updateMode);
        };
    }, []);

    return isDarkMode ? (
        <FontAwesomeIcon icon={props.icon} className="fa-sm" color="#BABFC4" style={{ filter: 'grayscale(0) invert(0)' }} />
    ) : <FontAwesomeIcon icon={props.icon} className="fa-sm" color="#082535" style={{ filter: 'grayscale(0) invert(0)' }}/>
}

export default ResponsiveIcon;