import React, {useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Posts from "../components/Posts"
import {useParams} from "react-router-dom";
import RightBar from "../components/RightBar";
import SideBar from "../components/SideBar";
import Container from "react-bootstrap/Container"
import PriceChart from "../components/PriceChart";
import fetchData from "../utils/Fetch";

function Ticker() {
    const {ticker} = useParams();
    useEffect(() => {
        document.title = `CoinTool - ${ticker} to USD`
    }, [ticker])
    const [icon, setIcon] = React.useState('');
    useEffect(() => {
        fetchData(`/api/symbols?search=${ticker}`).then((response) => {
            if (response) {
                setIcon(response.symbols.filter((symbol: any) => symbol.ticker === ticker)[0].icon)
            }
        })
    }, [ticker])
    return (
        <Row>
            <Col xs="auto" className="m-0 p-0">
                <SideBar/>
            </Col>
            <Col className="m-0 p-0 mx-lg-5 mt-3">
                <Container>
                    <Row>
                        <Col>
                            <PriceChart symbol={ticker} />
                            <Container className="mx-0 px-0 mb-2 w-100 numeric-text-color"><img src={icon} alt={ticker} className="ticker-icon mb-1" /> <b className="fw-bold mx-1">{ticker} News</b></Container>
                            <Posts/>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <RightBar/>
        </Row>
    );
}

export default Ticker;