import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Paginate = (props: {
    total: number,
    page: number,
    pageSize: number,
    paginate: (page: number) => void,
    setPreviousPage: () => void,
    setNextPage: () => void,
    setPageSize: (size: number) => void,
    pageSizeOptions: number[],
}) => {
    const pageNumbers = [];
    const maxPage = Math.ceil(props.total / props.pageSize);

    if (props.page >= 2 && props.page <= maxPage - 1) {
        if (props.page !== 2) {
            pageNumbers.push(1)
        }
        pageNumbers.push(props.page - 1)
        pageNumbers.push(props.page)
        pageNumbers.push(props.page + 1)
        if (props.page !== maxPage - 1) {
            pageNumbers.push(maxPage)
        }
    }
    if (props.page === 1) {
        pageNumbers.push(1);
        if (maxPage > 1) {
            pageNumbers.push(2)
        }
        if (maxPage > 2) {
            pageNumbers.push(3)
        }
        if (maxPage > 3) {
            pageNumbers.push(maxPage)
        }
    }
    if (props.page === maxPage && maxPage !== 1) {
        if (maxPage > 3) {
            pageNumbers.push(1)
        }
        if (maxPage > 2) {
            pageNumbers.push(maxPage - 2)
        }
        if (maxPage > 1) {
            pageNumbers.push(maxPage - 1)
        }
        pageNumbers.push(maxPage)
    }
    const pageNumbersWithSpaces = []
    let lastNumber = 0
    for (let i = 0; i < pageNumbers.length; i++) {
        if (i !== 0 && pageNumbers[i] - 1 !== lastNumber) {
            pageNumbersWithSpaces.push(0)
            pageNumbersWithSpaces.push(pageNumbers[i])
        } else {
            pageNumbersWithSpaces.push(pageNumbers[i])
        }
        lastNumber = pageNumbers[i]
    }


    return (
        <div className="paginate-container">
            <Row>
                <Col>
                    <ul className="paginate p-0 float-start">
                        <li onClick={props.setPreviousPage} className="page-number float-start">
                            <FontAwesomeIcon icon={faArrowLeft} className="fa-sm"/>
                        </li>
                        {pageNumbersWithSpaces.map((number, i) => (
                            <li
                                key={i}
                                onClick={() => {
                                    if (number !== 0) {
                                        props.paginate(number)
                                    }
                                }}
                                className={`${number !== 0 ? 'page-number' : 'page-number-dots'} float-start ${number === props.page ? 'active' : ''}`}
                            >
                                {number !== 0 ? number : '...'}
                            </li>
                        ))}
                        <li onClick={props.setNextPage} className="page-number float-start">
                            <FontAwesomeIcon icon={faArrowRight} className="fa-sm"/>
                        </li>
                    </ul>
                </Col>
                <Col className="col-4 d-none d-lg-block">
                    <ul className="paginate text-end float-end">
                        {props.pageSizeOptions.map((number) => (
                            <li
                                key={number}
                                onClick={() => props.setPageSize(number)}
                                className={`page-number float-end ${number === props.pageSize ? 'active' : ''}`}
                            >
                                {number}
                            </li>
                        )).reverse()}
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

export default Paginate;