import React, {useState} from "react";
import Message from "./Message";
import {auth} from "../config/firebase";
import {applyActionCode, verifyPasswordResetCode, confirmPasswordReset} from "firebase/auth";
import {Link} from "react-router-dom";

function ActionForm() {
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const query = new URLSearchParams(window.location.search);
    const oobCode = query.get('oobCode')
    const mode = query.get('mode')

    React.useEffect(() => {
        if (mode !== "resetPassword" && mode !== "verifyEmail") {
            window.location.replace("/")
        }
        if (mode === "verifyEmail") {
            if (!oobCode) {
                window.location.replace("/")
                return
            }
            setMessage("We are verifying your email...")
            setMessageClass("text-success")
            applyActionCode(auth, oobCode).then(() => {
                setMessage("Your email has been verified!")
                setMessageClass("text-success")
                setTimeout(() => {
                    window.location.replace("/user/settings")
                }, 5000)
            }).catch((e: any) => {
                setMessage(e.message.replace("Firebase: ", ""))
                setMessageClass("text-danger")
            })
        }
    }, [mode, oobCode]);

    const resetPassword = async (e: any) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage("Passwords do not match")
            setMessageClass("text-danger")
            return
        }
        if (!oobCode) {
            setMessage("Invalid code")
            setMessageClass("text-danger")
            return
        }
        verifyPasswordResetCode(auth, oobCode).then((email) => {
            confirmPasswordReset(auth, oobCode, password).then((resp) => {
                setMessage("Password reset succeeded!")
                setMessageClass("text-success")
                setTimeout(() => {
                    window.location.replace("/login")
                }, 5000)
            }).catch((e: any) => {
                    setMessage(e.message.replace("Firebase: ", ""))
                    setMessageClass("text-danger")
                }
            )
        }).catch((e: any) => {
                setMessage(e.message.replace("Firebase: ", ""))
                setMessageClass("text-danger")
            }
        )
    }

    return (
        mode === "resetPassword" ?
        <div>
            <Link to="/">
                <button className="btn btn-primary w-100 mt-2 home-button numeric-dark-blue">
                    Home
                </button>
            </Link>
            <form className="rounded-3 p-3 mt-3 auth-form" onSubmit={resetPassword}>
                <div className="form-group p-2">
                    <label className="font-weight-bold">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className="form-group p-2">
                    <label>Confirm</label>
                    <input
                        type="password"
                        className="form-control"
                        onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                </div>
                <div className="form-group text-center">
                    <img src="/reCAPTCHA.png" className="recaptcha-logo" alt="recaptcha"/>
                </div>
                <div className="form-group p-2">
                    <button type="submit" className="btn btn-primary w-100 mt-2 log-in-button">
                        Reset
                    </button>
                </div>
                <div className="form-group m-2">
                    <Message message={message} class={messageClass}/>
                </div>
            </form>
        </div> : <div>
                <Link to="/">
                    <button className="btn btn-primary w-100 mt-2 home-button numeric-dark-blue">
                        Home
                    </button>
                </Link>
                <form className="rounded-3 p-3 mt-3 auth-form">
                    <div className="form-group text-center">
                        <img src="/reCAPTCHA.png" className="recaptcha-logo" alt="recaptcha"/>
                    </div>
                    <div className="form-group m-2">
                        <Message message={message} class={messageClass}/>
                    </div>
                </form>
            </div>
    );
}

export default ActionForm;