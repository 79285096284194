import {Typeahead} from 'react-bootstrap-typeahead';
import React, {useEffect, useState} from "react";
import fetchData from "../utils/Fetch";

const TickerSearchBar = (params: any) => {
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState('');

    const handleOnChange = (selected: any) => {
        const ticker = selected[0]
        if (ticker) {
            window.location.href = `/symbol/${ticker.split(' ')[0]}`
        }
    };

    const handleInputChange = (input: any) => {
        setSearch(input)
    };

    useEffect(() => {
        fetchData(`/api/symbols?search=${search.toUpperCase()}`).then((response) => {
            if (response) {
                setOptions(response.symbols.map((symbol: any) => symbol.symbol))
            }
        })
    }, [search])

    return (
        <Typeahead id="search-ticker"
                   options={options}
                   placeholder="Symbol"
                   onChange={handleOnChange}
                   onInputChange={handleInputChange}
        />
    )
};

export default TickerSearchBar;