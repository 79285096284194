import React from "react";

function Message(props: { message: string, class: string }) {
    return (
        <small className={props.class}>
            {props.message}
        </small>
    )
}

export default Message;