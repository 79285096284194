import React from "react";
import Box from "../components/Box";
import ResetPasswordForm from "../components/ResetPasswordForm";

function Login() {
    return (
        <Box component={<ResetPasswordForm/>}/>
    );
}

export default Login;