import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useState, useEffect} from 'react';

function Box(props: { component: any }) {
    const [dynamicHeight, setDynamicHeight] = useState(0);
    useEffect(() => {
        const childDivHeight = document.getElementById("box-container")?.clientHeight;
        setDynamicHeight(childDivHeight ? childDivHeight : 400);
    }, []);

    return (
        <Row>
            <Col className="m-0 p-0 text-center box" style={{
                minHeight: `${dynamicHeight}px`,
            }}>
                <div className="m-0 p-0 text-start box-container" id="box-container">
                    {props.component}
                </div>
            </Col>
        </Row>
    )
}

export default Box;